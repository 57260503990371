import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComparePasswordDirective } from "./compare-password.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [ComparePasswordDirective],
  exports: [ComparePasswordDirective]
})
export class ComparePasswordModule {}
